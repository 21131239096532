const { getC1UserRole } = useAppUtils();

export function useGigyaManagerService() {
  const getAllUserData = async () => {
    if (process.server) return;
    return new Promise((resolve) => {
      window.gigya.accounts.getAccountInfo({
        extraProfileFields: 'samlData',
        include: 'data,profile,regSource,preferences',
        callback: (res: any) => {
          res.c1UserRole = getC1UserRole(res);
          resolve(res);
        }
      });
    });
  };

  const gigyaLoadedPromise = () => {
    return gigyaLoad;
  };

  const initGigyaLoadPromise = () => {
    if (process.server) return;
    return new Promise((resolve) => {
      window.addEventListener('gigyaLoaded', () => resolve({}));
    });
  };

  const gigyaLoad = initGigyaLoadPromise();

  return {
    gigyaLoadedPromise,
    getAllUserData
    //initGigyaObserver
  };
}
